::placeholder {
  color: #ccc !important;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ccc !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ccc !important;
}

.customTable {
  text-align: center;
}

.customTable table {
  /* box-shadow: inset 0 0 4px 0px rgba(136, 152, 170, 0.15); */
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.02);
}

.customTable .table th,
.customTable .table td {
  vertical-align: middle;
  padding: 1.5rem 1rem;
  border-top: 0;
  /* border-bottom: 1px solid #e9ecef; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;
  /* white-space: nowrap; */
}

.customTable tbody tr td:last-child {
  min-width: 130px;
}

.customTable .table th {
  /* color: #bfc5d2; */
  font-size: 14px;
  padding: 1rem 1.5rem;
}

/* .customTable .table .tableBody tr:last-child td {
  border-bottom: 0;
} */
.customTable .table .tableBody tr {
  background-color: #fff;
}

.link-btn {
  color: #007bff !important;
}

select,
select.form-control,
input.form-control {
  border-radius: var(--border-radius-default);
}

input[type="search"] {
  border-radius: 50px;
}

.cardIcon {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  font-size: 3rem;
  /* top: 1.9rem;
  right: 1.1rem;
  font-size: 1.5rem; */
}

section .nav-tabs {
  border-bottom: 0;
}

section .nav-tabs .nav-link {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  margin-right: 2.25rem;
}

/* section .nav-tabs .nav-link:nth-child(even) {
  margin-right: 2.25rem;
  margin-left: 2.25rem;
} */

section .nav-tabs .nav-link.active,
section .nav-tabs .nav-link {
  background-color: transparent;
  border-color: transparent;
  color: #000;
}

section .nav-tabs .nav-link:focus,
section .nav-tabs .nav-link:hover {
  border-color: transparent;
}

section .nav-tabs .nav-link.active {
  font-weight: bold;
}

section .nav-tabs .nav-link.active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5b5a3;
  bottom: 0;
  max-width: 40%;
  min-width: 25px;
  border-radius: var(--border-radius-default);
}

.mwx-200 {
  max-width: 215px !important;
}

.mwx-300 {
  max-width: 271px !important;
}

.mwx-400 {
  max-width: 400px !important;
}

.mwx-500 {
  max-width: 500px;
}

.tabFilters {
  position: relative;
  top: var(--inputHeight);
  margin-top: -var(--inputHeight);
}

.selectfilters {
  top: var(--inputHeight);
  margin-top: calc(var(--inputHeight) * -1);
}

/* .selectfilters select {
  border-radius: 20px;
} */

.selectfilters .form-group {
  margin-bottom: 0;
}

.btn.btn-primary:hover,
.btn.btn-primary {
  height: auto;
  padding: 0.75rem 2rem;
  border-radius: 50px;
  background-color: #000000;
  border-color: #000000;
}

.btn.btn-primary:not(:disabled):not(.disabled):active,
.btn.btn-primary.focus,
.btn.btn-primary:focus {
  /* border-color: #f5b5a3;
  background-color: #f5b5a3; */
  /* color: #000; */
  background-color: #000000;
  border-color: #000000;
  box-shadow: none !important;
}

.btn.btn-secondary {
  padding: 0.75rem 2rem;
  border-radius: 50px;
}

.btn.btn-secondary:not(:disabled):not(.disabled):active,
.btn.btn-secondary.focus,
.btn.btn-secondary:focus {
  box-shadow: none !important;
}

.tabBody {
  margin-top: 40px;
}

.tabBody.sb400 input[type="search"] {
  min-width: 350px;
}

.tabBody.offsetSearch {
  margin-top: -90px;
}

.tabFilters input[type="search"] {
  min-width: 250px;
}

.search-label {
  margin-bottom: 0;
}

.searchIcon svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
}

.searchIcon input {
  padding-left: 40px;
}

.customTable .search-label input {
  min-width: 250px;
  border-radius: 50px;
  max-width: 250px;
}

.rightContent select,
.rightContent input {
  border-color: #f6f6f6;
  height: var(--inputHeight);
}

.rightContent input[type="radio"] {
  height: auto;
}

.modal-body select.form-control,
.modal-body input.form-control {
  height: var(--inputHeight);
}

button.btn svg,
a svg {
  color: #000;
  font-size: 20px;
  pointer-events: none;
}

td a {
  padding: 0.375rem 0.75rem;
}

div.card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.02);
  border-radius: var(--border-radius-default);
}

div.card-body {
  padding: 1.75rem 1.25rem;
}

label.required::after {
  content: "*";
}

.modal-header {
  background-color: #000;
  color: #fff;
}

.modal-header .close {
  opacity: 1;
}

.modal-header .close span {
  color: #fff;
  font-size: 30px;
  text-shadow: none;
}

.no-wrap {
  white-space: nowrap;
}

.inline-form div.col-auto {
  padding-right: 20px;
}

.inline-form label {
  white-space: nowrap;
  margin: 0;
  min-width: 150px;
}

.inline-form input,
.inline-form select {
  min-width: 250px;
}

.stepForm div>a,
.stepForm div>span,
.stepForm div>div {
  /* display: block; */
  line-height: 34px !important;
}

.masterWorkouform textarea[readonly],
.stepForm textarea[readonly],
.challenge_form textarea[readonly] {
  background-color: #fff;
}

.modal-lg.big {
  max-width: 1000px;
}

hr.dark {
  border-color: #000;
}

.text-underline {
  text-decoration: underline;
}

.max-200 {
  max-width: 200px;
}

.max-300 {
  max-width: 300px;
}

.max-400 {
  max-width: 400px;
}

.rightContent.notFoundPage {
  display: flex;
  align-items: center;
}

.rightContent.notFoundPage .notFoundBox {
  max-width: 900px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}

.rightContent.notFoundPage img {
  max-width: 400px;
  margin: 0 auto;
  display: block;
}

.editCtrls {
  display: none;
}

/* .rightContent.ReadOnly textarea, */
.rightContent.ReadOnly input,
.rightContent.ReadOnly select,
.rightContent.ReadOnly button,
.rightContent.ReadOnly svg,
.rightContent.ReadOnly .ck.ck-editor,
.rightContent.ReadOnly .basic-multi-select,
.rightContent.ReadOnly .selectAllBtn,
.rightContent.ReadOnly .close,
.rightContent.ReadOnly .addBtn,
.ReadOnly .selectAllBtn,
.ReadOnly .basic-multi-select {
  pointer-events: none;
}

.ReadOnly .basic-multi-select.new .select__control {
  background-color: #e9ecef;
}

.rightContent.ReadOnly .mainCtrls {
  display: none !important;
}

.rightContent.ReadOnly .editCtrls {
  display: block;
}

.rightContent.ReadOnly .editCtrls button {
  pointer-events: all;
  pointer-events: unset;
}

.rightContent:not(.ReadOnly) .editCtrls {
  display: none !important;
}

.text-active {
  position: relative;
  line-height: 2;
}

.text-active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5b5a3;
  bottom: 0;
  max-width: 50px;
  min-width: 25px;
  border-radius: var(--border-radius-default);
}

.form-m-0 .form-group {
  margin-bottom: 0;
}

.font-14 {
  vertical-align: middle;
  font-size: 14px !important;
}

.font-20 {
  vertical-align: middle;
  font-size: 22px;
}

.cursor-pointer {
  cursor: pointer;
  user-select: none;
}

.btn.btn-primary.disabled,
.btn.btn-primary:disabled,
.btn.btn-secondary:disabled,
.btn.btn-secondary:disabled {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.01);
}

.toastMessage {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 9199991919;
}

.toastMessage .toast {
  background-color: #fff;
  color: #000;
  /* border-radius: 15px; */
}

.bold {
  font-weight: bold;
}

.err-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

select.form-control.is-invalid,
.was-validated select.form-control:invalid,
select.form-control.is-valid,
.was-validated select.form-control:valid {
  background-position: right calc(1.375em + 0.1875rem) center;
}

.imgUploadBox {
  width: 100%;
  height: 120px;
  border-radius: var(--border-radius-default);
  background-color: #fff;
  position: relative;
  overflow: hidden;
}

.imgUploadBoxDietLibrary {
  min-width: 24%;
  height: 35px;
  border-radius: var(--border-radius-default);
  background-color: #000;
  position: relative;
  overflow: hidden;
}

.imgUploadBox input {
  opacity: 0;
  user-select: none;
  position: absolute;
  cursor: pointer;
  /* background: red; */
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: 9;
}

.imgUploadBoxDietLibrary input {
  opacity: 0;
  /* width: 20px!important; */
  user-select: none;
  position: absolute;
  cursor: pointer;
  /* background: red; */
  top: 0;
  bottom: 0;
  height: 100%;
  z-index: 9;
}

.click_here_text_upload {
  font-weight: 600;
  text-decoration: underline;
}

.imgUploadBox svg {
  color: #bababa;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 45px;
  z-index: 1;
  pointer-events: none;
}

.imgUploadBoxDietLibrary svg {
  color: #fff;
  position: absolute;
  left: 19%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 23px;
  z-index: 1;
  /* pointer-events: none; */
}

.dietLibrarytxt {
  /* background-color: #007bff; */
  z-index: 1;
  color: #fff;
  padding-left: 45px;
  padding-right: 15px;
  font-size: 15px;
}

.view_eye_icon {
  font-size: 17px !important;
  /* background-color: #007bff; */
}

.imgUploadBox::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 29px;
  height: 29px;
  background-color: #fff;
  z-index: 1;
  pointer-events: none;
}

.imgUploadBox small {
  position: absolute;
  top: calc(50% + 35px);
  transform: translateY(-50%);
  font-size: 14px;
  width: 100%;
  text-align: center;
  z-index: 1;
}

.imgUploadBox+.err-feedback {
  /* position: absolute;
  top: calc(50% + 70px);
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  text-align: center; */
  top: unset;
  display: block;
  left: unset;
  transform: none;
}

#addLevels .imgUploadBox+.err-feedback {
  top: calc(50% + 85px);
}

.imgUploadBox img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.popupImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageBoxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 40px;
  padding-left: 20px;
}

.basic-multi-select,
.basic-single {
  z-index: auto;
}

.basic-multi-select .select__control {
  line-height: 1.5;
  color: #000;
  border-radius: var(--border-radius-default);
  min-height: var(--inputHeight);
  border: 1px solid #ced4da;
}

.basic-multi-select .select__multi-value {
  border-radius: 50px;
}

.customSelect_mwx .select__multi-value {
  max-width: 170px;
}

.basic-multi-select .select__multi-value__label {
  color: #000;
}

.basic-multi-select .select__menu {
  z-index: 9;
}

svg>path {
  pointer-events: none;
}

.workout_form [class^="col-md"] .form-group {
  position: relative;
}

.workout_form [class^="col-md"] .invalid-feedback {
  position: absolute;
  bottom: -25px;
}

.workout_form [id^="circuit_name"]+.invalid-feedback {
  position: absolute;
  bottom: -25px;
}

.workout_form .imgBx small.err-feedback {
  /* top: calc(50% + 90px); */
  /* top: calc(100% + 15px); */
  width: 100%;
  line-height: 1.25;
  max-height: 40px;
  overflow-y: auto;
}

.challenge_form [class^="col-md"] .form-group {
  position: relative;
}

.challenge_form [class^="col-md"] .invalid-feedback {
  position: absolute;
  bottom: -18px;
}

.challenge_form [id^="circuit_name"]+.invalid-feedback {
  position: absolute;
  bottom: -18px;
}

.challenge_form .imgBx .err-feedback {
  top: calc(50% + 90px);
}

.modal-body .imgUploadBox {
  box-shadow: 0 0 15px rgb(0 0 0 / 0.1);
}

.cards_div p {
  margin-bottom: 0;
}

.searchIcon-minus {
  display: block;
  margin-bottom: -45px;
}

.datePickerBox input[type="text"] {
  min-width: auto;
  max-width: 117px;
  border-radius: var(--border-radius-default);
  border-color: transparent;
  outline: none;
  font-size: 14px;
  text-align: center;
}

.datePickerBox_Wk input[type="text"] {
  min-width: auto;
  max-width: auto;
  border-radius: var(--border-radius-default);
  border-color: transparent;
  outline: none;
  font-size: 14px;
  text-align: left;
  padding-left: 12px;
}

div.react-datepicker-popper {
  z-index: 4;
}

div.react-confirm-alert-body {
  /* width: 450px; */
  width: fit-content;
  width: auto;
}

div.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.5);
  z-index: 9994;
}

.char-len {
  position: absolute;
  right: 10px;
  bottom: 0px;
}

.thead-dark th {
  white-space: nowrap;
}

div .custom-control-input:checked~.custom-control-label::before {
  border-color: #000;
  background-color: #000;
}

div.custom-switch.custom-control .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: #6c757d;
  border-color: #6c757d;
}

/* div .custom-control-label::before {
  border-color: #000;
} */
div.rounded {
  border-radius: var(--border-radius-default) !important;
}

/* div.spinner-border {
  border: 0.25em solid #f5b5a3;
  border-right-color: transparent;
} */

form.was-validated .form-check-input:valid~.form-check-label {
  color: #000;
}

textarea.form-control {
  padding-bottom: 15px;
}

/* .ck.ck-sticky-panel__content.ck-sticky-panel__content_sticky {
  z-index: 1;
} */

.font-black {
  color: #000;
}

.fontBlacks label {
  color: #000 !important;
}

div.top-auto {
  top: auto !important;
}

section.addLibrary .tab-content {
  margin-top: 1.5rem;
}

.margin-top-0 {
  margin-top: -45px;
}

.row.react-bootstrap-table-pagination {
  margin-top: 1.5rem;
}

.mt-pg {
  margin-top: -30px;
}

.mt-ng {
  margin-bottom: -30px !important;
}

.datePickerBox_Cs input[type="text"] {
  min-width: auto;
  max-width: 300px;
  border-radius: var(--border-radius-default);
  border: 1px solid #ced4da;
  outline: none;
  padding: 0.375rem 0.75rem;
  height: var(--inputHeight);
}

.datePickerBox_Cs input[type="time"] {
  min-width: 100px;
}

#notificationForm .form-check-label {
  color: #000;
}

.customSelect input {
  max-height: var(--inputHeight);
}

.customSelect .basic-multi-select .select__control {
  border: none;
}

.customSelect .basic-multi-select .select__value-container,
.customSelect .basic-multi-select [class^="css-"] {
  padding-bottom: 0;
  padding-top: 0;
  margin-bottom: 0;
  margin-top: 0;
}

.tabsDisabled {
  position: relative;
}

.tabsDisabled::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(246, 246, 246, 0.4);
  z-index: 9;
}

.tabsDisabled .nav,
.tabsDisabled .btn {
  filter: blur(0.5);
  pointer-events: none;
}

label[for="makeTopTrendBox"] {
  display: none;
}

#makeTopTrendBox {
  height: unset;
  height: auto;
}

.height-cst {
  height: max-content;
}

.videoPage {
  padding-top: 0;
  min-width: initial;
  font-family: inherit;
}

.videoPage header.main {
  display: none;
}

.videoPage * {
  font-family: "Poppins", "Helvetica Neue", sans-serif;
}

.player-wrapper {
  position: relative;
  /* padding-top: 56.25%; */
  padding-top: 100%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.react-player>div {
  border-radius: 10px;
}

.react-confirm-alert-body>h1 {
  font-size: 2rem;
}

.colNoWrap {
  white-space: nowrap !important;
  width: 300px;
}

.CircularProgressbar {
  max-width: 60px;
  width: 100%;
}

.CircularProgressbar text.CircularProgressbar-text {
  font-size: 30px;
}

.form-control.is-valid.noValidate,
.was-validated .form-control.noValidate:valid {
  border-color: transparent;
  background-image: none;
  padding: 0.375rem 0.75rem;
}

.onboardImg {
  max-height: 150px;
  object-fit: contain;
}

.multiImageBox {
  position: relative;
}

.multiImageBox.Img img {
  width: 100%;
  height: 100%;
  max-width: 150px;
  min-width: 150px;
  object-fit: cover;
}

.masterWorkouform .multiImageBox.Img img {
  max-width: 100%;
}

.multiImageBox.prev img {
  width: 100%;
  height: 100%;
  max-width: 90px;
  max-height: 90px;
  min-width: 90px;
  object-fit: cover;
}

.multiImageBox .close {
  position: absolute;
  right: 8px;
  top: -8px;
  background-color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  z-index: 2;
  cursor: pointer;
  font-size: 16px;
}

.videoBoxs .close {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  z-index: 2;
  cursor: pointer;
  font-size: 16px;
}

.masterWorkouform .videoBoxs .close {
  right: -6px;
  top: -6px;
}

label,
label.col-form-label {
  font-size: 16px;
}

.prf-col .icon {
  position: absolute;
  top: 10px;
  right: 0px;
  font-size: 20px;
  color: #f5b5a3;
}

.addProgramForm .imgUploadBox+.err-feedback {
  top: calc(50% + 83px);
}

.prf-col {
  white-space: nowrap;
}

.prf-col .pertext {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translatex(-50%);
  font-size: 10px;
  background-color: #f5b5a3;
  /* color: #fff; */
  padding: 2px 8px;
  border-radius: 4px;
}

.count-col .ct-icons {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translatex(-50%);
  font-size: 14px;
  color: #f5b5a3;
}

.count-col .ct-icons svg:first-child {
  margin-right: 5px;
}

svg[disabled] {
  color: rgba(0, 0, 0, 0.2);
}

.stepNumbersBox .stepNumbers .num>button {
  width: 32px;
  height: 32px;
  margin: 0px auto;
  background-color: rgb(224, 224, 224);
  border-radius: 50%;
  text-align: center;
  padding: 1px;
  font-size: 16px;
  color: rgb(255, 255, 255);
  display: block;
  border-width: 0px;
  line-height: 34px;
  position: relative;
  z-index: 2;
}

.stepNumbersBox .stepNumbers .num>button[currentbtn="true"] {
  /* background-color: rgb(80, 150, 255); */
  background-color: #2c2c2c;
}

.weekContent .weeksTab .weekTab>button[currentbtn="true"] {
  background-color: #2c2c2c !important;
}

.stepNumbersBox .stepNumbers .num::after,
.stepNumbersBox .stepNumbers .num::before {
  content: "";
  position: absolute;
  top: 50%;
  height: 1px;
  width: 50%;
  border-top: 1px solid black;
  margin-left: 20px;
}

.stepNumbersBox .stepNumbers .num::before {
  left: -15px;
}

.stepNumbersBox .stepNumbers .num::after {
  right: -15px;
}

.stepNumbersBox .col:first-child .stepNumbers .num::before {
  display: none;
}

.stepNumbersBox .col:last-child .stepNumbers .num::after {
  display: none;
}

.stepNumbersBox .stepNumbers .txt {
  /* margin-top: 8px; */
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  display: block;
  color: rgb(0, 0, 0);
}

.stepNumbersBox .stepNumbers .txt .line {
  position: absolute;
  top: 40px;
  height: 1px;
  border-top: 1px solid black;
  right: 0px;
  left: 50%;
  margin-left: 20px;
}

.multi .mul-box-ct .mul-box .add {
  display: none;
}

.multi .mul-box-ct .mul-box:nth-last-of-type(1) .add {
  display: unset;
}

.multi .mul-box-ct .mul-box:nth-last-of-type(1) hr {
  display: none;
}

.empty_space30 {
  padding: 30px;
}

.userNoSelect label {
  user-select: none;
}

.prgFeed {
  display: block;
  margin-top: -10px;
  margin-bottom: 1rem;
}

.cstimg .btn.active {
  font-weight: bold;
  /* box-shadow: 0 0 10px rgb(0 0 0 / 10%) !important; */
}

.crop-data {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  /* border-top: 1px solid #000;
  border-right: 1px solid #000; */
  padding: 2px 6px;
  font-size: 14px;
}

.cropZoomBtn {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  /* border-top: 1px solid #000;
  border-right: 1px solid #000; */
  /* padding: 2px; */
  font-size: 12px;
  display: flex;
  flex-direction: column;
}

.cropZoomBtn button {
  padding: 0;
  margin: 4px;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
}

.cropZoomBtn button svg {
  color: #000;
  font-size: 18px;
}

.tooltip {
  z-index: 9996 !important;
}

form.was-validated .form-control:valid,
form.was-validated .form-control:valid:focus {
  border-color: inherit;
  border-color: #ced4da;
  background-image: none;
  /* border-color: #80bdff; */
  outline: 0;
}

form.was-validated .form-control:valid:focus {
  /* box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 25%); */
  box-shadow: none;
}

.tab-content .card-body figure {
  display: none !important;
}

.ck a,
.cards_div a {
  text-decoration: underline !important;
  color: #007bff !important;
}

.ck-content .table {
  width: auto;
}

.ck-editor ul,
.cards_div ul {
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.fixedHeightTable .react-bootstrap-table {
  height: 100%;
  max-height: 50vh;
  min-height: 500px;
}

.fixedHeightTable table th {
  position: sticky;
  top: 0;
  z-index: 2;
}

.btnLinkUs {
  text-decoration: underline !important;
}

.ck.ck-dropdown .ck-dropdown__arrow {
  z-index: 0 !important;
}

/* PAGINATOR */
.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}

.pagination>.active>a {
  background-color: #f5b5a3;
  border-color: #f5b5a3;
  color: #fff;
}

.pagination>li>a {
  border: 1px solid #f5b5a3;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  background-color: #f5b5a3;
  border-color: #f5b5a3;
  outline: none;
}

.pagination>li>a,
.pagination>li>span {
  color: #f5b5a3;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border-radius: unset;
}

.category_filter {
  margin-left: -15px !important;
}

.search-register-user {
  width: 280px !important;
  margin-bottom: -40px;
  margin-top: 0px;

}

.multi_select_notification_dropdown {
  max-width: 280px;
  width: 260px;
  margin-right: 30px;
}

.btn_dt_library {
  margin-top: 35px;
}

.edit_dt_library {
  display: flex;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;
}

.search-border {
  border-radius: 50px !important;

}

.select_filter {
  width: 300px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 45px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 45px !important;
  border-radius: 8px !important;
  border: none !important;
}

.ant-select.readOnly {
  width: 300px !important;
  pointer-events: none;
}

.search-register-users {
  width: 280px !important;
  margin-right: 6.7rem;
}

.search-notification-user {
  width: 480px !important;
  margin-right: 6.7rem;
}

.search-dietplan {
  width: 280px !important;
  display: flex;
  margin-right: 15em;
}

.diet_plan_assign {
  margin-left: -40px;
}

.weekButton,
.colorWeekBtn {
  color: black;
  background: white;
  border: 1px solid #f5b5a3 !important;
  border-color: #f5b5a3 !important;
  border-radius: 0 !important;
}

.colorWeekBtn {
  color: white;
  background: #f5b5a3;
}

.totalWeekContent {
  background: #ffffff !important;
  margin-top: 10px !important;
  padding: 12px !important;
}

.img-challenge {
  margin-top: 25px;
}

.workout-category {
  border-color: #000;
  width: 500px;
}

.defaultSelectBox {
  margin-bottom: -40px;
}

.search-deitPlan {
  margin: auto;
}

.modal-padding {
  padding: 0 !important;
}

figure {
  overflow-x: auto;
}

figure table {
  width: 100%;

}

figure table td {
  border: 1px solid #dee2e6;
}

/*---------- Designed Popup ------------- */

.img_mob_div {
  /* filter: blur(8px);
  -webkit-filter: blur(8px); */
  /* background-color: #007bff; */
  /* display: flex; */
  /* justify-content: center; */
  /* width: 100%; */
  position: relative;
  padding-bottom: 15px;
  color: #fff;
}

.top-recommend-txt {
  position: absolute;
  top: 5px;
  right: 8px;
  border-radius: 8px;
  background-color: rgb(129, 219, 219, .7);
  padding: 0px 8px;
}

.top_mob_img {
  width: 100%;
  height: 200px;
  width: 236px;
  object-fit: cover;
  border-radius: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.blur {
  filter: blur(3px);
  -webkit-filter: blur(px);
}

.large-font {
  font-size: 18px;
}

.small-font {
  font-size: 12px;
}

.x-small-font {
  font-size: 10px;
}

.xl-small-font {
  font-size: 8px;
}

.medium-font {
  font-size: 17px;
}

.s-medium-font {
  font-size: 15px;
}

.sec-medium-font {
  font-size: 13px;
}

.x-medium-font {
  font-size: 14px;
}

.activity-tracker {
  display: flex;
  /* justify-content: space-between; */
  padding: 10px 0px;
}

.activity-tracker div:last-child {
  border: none;
}

.sub-activity {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #007bff; */
  /* padding-right: 15px; */
  /* padding: 10px 15px 10px 10px; */
  padding: 6px 4px 6px;
  gap: 5px;
  border-right: 2px solid lightgray;
}

.others-imgs {
  display: flex;
  gap: 8px;
}

.recommend-img {
  position: relative;
  height: 99px;
  width: 114px;
  color: #fff;
}

.thumbnail_img {
  /* position: relative; */
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.activity-icon {
  height: 20px;
}

.title-heading {
  position: absolute;
  bottom: 2px;
  left: 10px;
}

.recommended-txt {
  position: absolute;
  right: 3px;
  top: 6px;
  border-radius: 8px;
  background-color: rgb(129, 219, 219, .7);
  /* opacity: 0.8; */
  padding: 0px 8px;
}

.logo-style {
  height: 10px;
  /* padding-top: 60px; */
  /* margin-top: 20px; */
}

.logo-styles {
  height: 10px;
}

.dumbbell_img {
  height: 25px;
}

.modal-90w {
  width: 50vw !important;
}

.brand-logo {
  height: 16px;

}

.popup-header {
  display: flex;
  gap: 30px;
  /* justify-content:space-evenly; */
  padding-top: 10px;
  padding-bottom: 20px;
}

.profile-reboot {
  /* background-color: #007bff; */
  padding: 0px 20px 15px 43px;
}

.profile {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.training-desc {
  text-align: center;
  padding: 13px 8px 10px;
}

.program-button-style {
  display: flex;
  justify-content: center;
  padding-top: 8px;
}

.start-program-button {
  color: #fff;
  background-color: #000;
  padding: 8px 15px;
  border-radius: 50px;
}

/* .total-popup-style {
  position: relative;
} */
.mobile {
  /* height: 550px; */
  height: 715px;
  width: 560px;
  position: relative;
  left: -140px;
  /* z-index: 1; */
}

.program-preview .mobile {
  height: 550px;
}

.sub-popup-style {
  position: absolute;
  top: 65px;
  right: 23px;
}

.left-spacing-header {
  padding-left: 30px;
}

.left-spacing {
  padding-left: 32px;
}

.backArrow {
  height: 15px;
}

.date-input {
  width: 50%;
  padding: 8px 25px 8px 10px;
  border-radius: 10px;
}

.input-style {
  /* background-color: #007bff; */
  display: flex;
  justify-content: center;
  padding-bottom: 3px;
}


/*---------------- New Preview popup style --------------------- */
.workout-popup-style {
  position: absolute;
  top: 45px;
  left: 0px;
}

.workout-popup-header {
  display: flex;
  align-items: center;
  gap: 30px;
  /* justify-content:space-evenly; */
  padding-top: 10px;
  padding-bottom: 10px;
}

.log-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img_mob_div {
  /* filter: blur(8px);
  -webkit-filter: blur(8px); */
  /* background-color: #007bff; */
  /* display: flex; */
  /* justify-content: center; */
  /* width: 100%; */
  position: relative;
  padding-bottom: 15px;
  color: #fff;
}

.featured_img {
  width: 100%;
  height: 100px;
  width: 233px;
  /* object-fit: cover; */
  filter: brightness(70%);
  border-radius: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.feature-desc-txt {
  position: absolute;
  bottom: 20px;
  left: 10px;
  border-radius: 8px;
  /* background-color: rgb(129, 219, 219, .7); */
  padding: 0px 8px;
}

.favorite_img {
  /* width:100%; */
  height: 80px;
  width: 112px;
  object-fit: cover;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.favorites-tab {
  display: flex;
  gap: 8px;
}

.favorites-sub-tab {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.mobility-picture {
  position: relative;
}

.mobility_img {
  /* width:100%; */
  height: 60px;
  width: 112px;
  object-fit: cover;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.top-mobility-txt {
  position: absolute;
  top: 5px;
  right: 8px;
  border-radius: 8px;
  background-color: rgb(206, 64, 64);
  color: #fff;
  padding: 0px 8px;
}

.mobility-tab {
  display: flex;
  gap: 8px;
}

.bottom-navbar {
  display: flex;
  /* background-color: #007bff; */
  justify-content: space-between;
  border-top: 1px solid lightgray;
  padding: 5px 8px;
}

.left-spacing-bottom {
  margin-left: 30px;
  padding-left: 20px;
}

/*--------------------- Mobility Popup ----------------------*/
.main-full-stretch-tabs {
  padding-top: 10px;
}

.mobility-workout {
  margin-top: 4px;
}

.full-stretch-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #F5B5A3;
  border-radius: 10px;
}

.fullStretch_img {
  /* width:100%; */
  height: 57px;
  width: 80px;
  object-fit: cover;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  border-radius: 10px;
}

.new-mobility-txt {
  background-color: #dc3545;
  border-radius: 10px;
  padding: 0px 5px;
  color: #fff;
  font-weight: 600;
  font-size: 8px;
}

.exercise-txt {
  color: gray;
}

.mobility-bottom-navbar {
  display: flex;
  /* position: absolute; */
  /* background-color: #007bff; */
  justify-content: space-between;
  border-top: 1px solid lightgray;
  padding: 5px 8px;
}

.equipment-txt {
  font-weight: 600;
  padding-top: 10px;
}

.map-icon {
  border: 1px dashed lightgray;
  padding: 0px 8px;
  width: fit-content;
  border-radius: 10px;
}

.start-workout-txt {
  display: flex;
  justify-content: end;
}

.workout-txt-button {
  width: fit-content;
  padding: 5px 8px;
  border: 1px dashed black;
  border-radius: 10px;
}

.footer-style {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-style img {
  height: 17px;
}

.view-more-icon {
  height: 12px;
}

.right-arrow-icon-style {
  height: 11px;
  padding-right: 10px;
}

.colorHeart {
  height: 13px;
}

.notification-icon-style {
  height: 20px;
}

.upper-body-margin {
  margin-bottom: 65px;
}

/*--------------------------- Challenge Workout tab -------------------- */
.challenges_img {
  /* width:100%; */
  height: 55px;
  width: 50px;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
}

.challenges-right-arrow-icon-style {
  height: 10px;
  padding-right: 8px;
}

.tickMark {
  height: 11px;
  padding-left: 2px;
}

.upcoming-challenge-tab {
  margin-bottom: 69px;
}

.upcoming-challenge-width {
  /* background-color: #007bff; */
  width: 145px;
  padding: 5px 0px;
}

.circleRightArrow {
  height: 16px;
}

.start-now-content {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.normal-txt {
  font-weight: lighter !important;
}

::-webkit-file-upload-button {
  cursor: pointer;
}



/*------------------Media screen------------------- */
@media only screen and (min-width: 1000px) and (max-width: 1400px) {
  .imgUploadBoxDietLibrary {
    min-width: 30%;
    height: 35px;
    border-radius: var(--border-radius-default);
    background-color: #000;
    position: relative;
    overflow: hidden;
  }

  .imgUploadBoxDietLibrary svg {
    color: #fff;
    position: absolute;
    left: 18%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 23px;
    z-index: 1;
    pointer-events: none;
  }

  .dietLibrarytxt {
    /* background-color: #007bff; */
    z-index: 1;
    color: #fff;
    padding-left: 45px;
    padding-right: 15px;
    font-size: 15px;
  }

  .view_eye_icon {
    font-size: 16px !important;
    /* background-color: #007bff; */
  }

}

.error-msg {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

/* new .. */
.eq-image-label {
  font-size: 14px;
  margin-bottom: 10px;
}

/*Manage Program*/
.addProgramForm .form-group {
  margin-bottom: 4rem;
}


.program-preview .sub-popup-style {
  position: absolute;
  top: 26px;
  right: 26px;
}

.program-preview .profile-reboot {
  padding: 0px 0px 0px 22px;
}

.program-preview .profile {
  border-radius: 29px 23px 0px 0px !important;
  height: 245px;
}

.program-preview .p-back-btn {
  position: absolute;
  top: 16px;
  left: 33px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  width: 32px;
  height: 32px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.program-preview .p-back-btn svg {
  color: #FFF !important;
}

.program-bottom-sec {
  position: relative;
  top: -40px;
  margin: 0px 0px 10px 22px;
  background: #FFFFFF;
  border-radius: 10px 10px 14px 14px;
  padding: 10px;
}

.p-details-1 {
  display: flex;
  justify-content: space-between;
}

.p-details-1 .badge {
  background: #D25151;
  border: 1px solid #D25151;
  border-radius: 4px;
  padding: 3px;
  font-size: 9px;
  color: #FFF;
  white-space: nowrap;
  align-self: center;
  font-weight: 500;
}

.p-details-1 .activity-icon {
  height: 10px;
  color: #FFF;
  font-size: 10px;
  padding-right: 2px;
}

.program-bottom-sec ul {
  list-style: circle;
  padding-left: 10px;
}

.font-12 {
  font-size: 12px !important;
}

.pre-desc {
  padding: 0 1px 0 22px;
  font-size: 10px;
}

.program-bottom-sec .p-tab {
  background: #F5F5F5;
  border-radius: 6px;
}

.program-bottom-sec .selectd-p-tab {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  color: #D25151;
  padding: 3px 0px 3px 3px !important;
}

/* .dubicon {
  opacity: 0.75;
  color: rgba(0, 0, 0, 0.75);
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  filter: blur(2px);
} */
.p-title {
  font-size: 15px;
  color: #D25151;
  text-align: center;
  font-weight: 700;
}

.pre-card {
  /* padding: 13px 10px 0 32px; */
  padding: 13px 0px 0 32px;
  display: flex;
}

.pre-card .card {
  background-color: rgb(221, 126, 126);
  height: 225px;
  width: 213px;
}

.pre-secondcard .card {
  width: 230px;
}

.p-card-title {
  text-align: center;
  font-size: 18px;
  color: #FFFFFF;
}

.pre-card .activity-tracker {
  color: #FFF;
  justify-content: center;
}

.pre-card .activity-tracker .sub-activity svg {
  font-size: 10px;
}

.p-card-desc {
  text-align: center;
  font-size: 11px;
  color: #FFF;
  margin-top: 29px;
}

.pre-details-btn {
  background: #000000;
  border: 1px solid #FFFFFF !important;
  color: #FFF;
  padding: 10px 25px !important;
}

.smallcard {
  background: rgba(0, 0, 0, 0.65);
  border-radius: 10px 0px 0px 10px;
  height: 193px;
  width: 27px;
  margin-top: 17px;
  margin-left: 3px;
}

.mask-custom {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 6px;
}

.bg-dumb {
  background-color: #e7e7e712 !important;
  background-image: none !important;
}

.activity-tracker .x-small-font {
  font-size: 8px;
}

.alignment-top-padding {
  padding-top: 35px;
  /* background-color: #007BFF; */
}

.alignment-top-margin {
  margin-top: 35px;
}

.fw-600 {
  font-weight: 600;
}

.instr-content {
  position: relative;
}

.instr-err-msg {
  position: absolute;
}

.programFaqTag {
  background: #D25151;
  border-radius: 5px !important;
  color: #FFFFFF;
  font-weight: 500 !important;
  font-size: 80% !important;
  padding: 9px 13px !important
}

.commonFaqTag {
  background: #929292;
  border-radius: 5px !important;
  color: #FFFFFF;
  font-weight: 500 !important;
  font-size: 80% !important;
  padding: 9px 15px !important
}

.pgm-search .form-control {
  min-width: 250px;
  border-radius: 50px;
  max-width: 250px;
}

.equipment-width {
  max-width: 400px !important;
  width: 100%;
}

.basic-multi-select .select__control--is-disabled {
  background-color: #e9ecef;
}

.btn-link:disabled svg {
  color: #adb5bd !important;
}

.modal-open #pageLoader {
  left: 50% !important;
}

.privacyEditor .ck-read-only p,
.privacyEditor .ck-read-only ol,
.privacyEditor .ck-read-only ul,
.privacyEditor .ck-read-only blockquote,
.privacyEditor .ck-read-only h2,
.privacyEditor .ck-read-only h1,
.privacyEditor .ck-read-only h3 {
  color: #acacacd4;
}

.privacyEditor .ck-toolbar__items .ck-disabled button,
.privacyEditor .ck-toolbar__items .ck-disabled {
  cursor: none;
}

.ltdEdition .form-check-input {
  width: 18px;
}

.ltdCheckBox .form-check-input {
  width: 15px;
}

.promoContentName.form-control:disabled,
.form-control[readonly] {
  background: #D2CFCF;
  border: 1px solid #CED4DA;
}

.promoImageDisable input {
  pointer-events: none;
}

.switch-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  color: #000000;
}

.switch-edit {
  border-radius: 4px !important;
  width: 86px;
  padding: 7px !important;
}

.err-msg {
  font-size: .875em;
  color: #dc3545;
  margin-top: 0.25rem;
}

.switch-select-program input[type="radio"] {
  cursor: pointer !important;
}

.switch-select-program .form-check-label {
  cursor: pointer !important;
}
.switch-btn:disabled{
  opacity:0.25 !important;
}