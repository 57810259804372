.btn {
    padding: 10px 1.5rem;
  }
  .marginTopReport {
    /* // margin-top: 2.5%; */
    height: 40px;
    width: 25%;
    white-space: nowrap;
  }
  .mtop {
    margin-top: 14px;
  }
  .spinner {
    animation: spin infinite 3s linear;
  }

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}